import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"

import './PostCard.css'

const PostCard = ({
  featuredimage,
  title,
  excerpt,
  date,
  slug,
  kategori = [],
  className = '',
  ...props
}) => (
  <Link to={slug} className={`PostCard ${className}`} style={{ textDecoration: 'none' }}>
      <div className="PostCard--Image relative">
        {featuredimage &&
        <Img
          fluid={featuredimage.childImageSharp.fluid}
          style={{ maxHeight: '200px' }}
          imgStyle={{ objectFit: 'cover' }}
          alt={title} />
        }
      </div>
    <div className="PostCard--Content">
      {title && <h3 className="PostCard--Title">{title}</h3>}
      <div className="PostCard--Category">
      <span className="subtitle is-size-6 is-block">
                      🕙 {date.slice(0, 10)} &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; 
                      <span>
                      {kategori && kategori[0]}
                      </span>
                    </span>
          
      </div>
      {excerpt && <div className="PostCard--Excerpt">{excerpt}</div>}
    </div>
  </Link>
)

export default PostCard